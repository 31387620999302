import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchServerList(type) {
  return axios.get(baseUrl + baseApiVersion + `/server/list/${type}`, {
    headers,
  });
}

export function fetchJobList(params) {
  return axios.get(
    baseUrl +
      baseApiVersion + `/job/list?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}&keyword=${params.keyword}` +
      `&contentsId=${params.contentsId}&serverId=${params.serverId}` +
      `&statuses=${params.statuses}`,
    // &requestDateRange.from=${params.requestDateRange.from}&requestDateRange.to=${params.requestDateRange.to}
    {
      headers,
    }
  );
}

/**
 * 신현호 - 수정해야함!!
 * vodUrl Info GET
 * @returns 
 */
export function fetchVodUrlList() {
  return axios.get(baseUrl + baseApiVersion + `/server/activation/Vod`, { headers });
}
